import React from "react";
import { withFormsy } from "formsy-react";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import { withStyles } from "@material-ui/core/styles";
import "./OCVFormComponents.css";

const styles = () => ({
  button: {
    marginTop: "5px",
  },
});

export default withStyles(styles)(
  withFormsy(function OCVGPS(props: any) {
    const getLocation = () => {
      // check if user's browser supports Navigator.geolocation
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          props.setValue(
            position.coords.latitude + "," + position.coords.longitude
          );
        });
      } else {
        console.log({
          html: "Sorry, your browser does not support this feature... Please Update your Browser to enjoy it",
          classes: "rounded",
        });
      }
    };
    const changeValue = (event: { currentTarget: { value: any } }) => {
      // setValue() will set the value of the component, which in
      // turn will validate it and the rest of the form
      // Important: Don't skip this step. This pattern is required
      // for Formsy to work.
      props.setValue(event.currentTarget.value);
    };

    const { classes, label, getValue, getErrorMessage, required } = props;
    return (
      <div className="OCVFormComponentsDiv">
        <TextField
          InputLabelProps={{ style: { fontSize: 12, width: "90%" } }}
          label={label}
          onChange={changeValue}
          fullWidth
          value={getValue() || ""}
          variant={"outlined"}
          required={required}
        />
        <Button
          className={classes.button}
          onClick={getLocation}
          variant="contained"
        >
          Get Location
        </Button>
        <span className="OCVFormComponentsSpan">{getErrorMessage()}</span>
      </div>
    );
  })
);
