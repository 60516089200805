import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Redirect, withRouter } from "react-router";
import Swiper from "react-id-swiper";
import * as moment from "moment";
import Paper from "@material-ui/core/Paper/Paper";
import Lightbox from "react-images";
import "./OCVBlogDetail.css";

export default withRouter(function OCVBlogDetail(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightbox] = useState(false);
  const openLightbox = (
    event: { preventDefault: () => void },
    index: number
  ) => {
    event.preventDefault();
    setCurrentImage(index);
    setLightbox(true);
  };
  const closeLightbox = () => {
    setLightbox(false);
  };
  const gotoPrevious = () => {
    setCurrentImage(currentImage - 1);
  };
  const gotoNext = () => {
    setCurrentImage(currentImage + 1);
  };

  const { route, blogEntries, subtypes, history, manifestData, displayDate } =
    props;
  const test = blogEntries.find((obj: any) => {
    return obj["_id"]["$id"] === props["match"]["params"]["id"];
  });
  let images: any[] = [];
  if (!test) {
  } else if (test.length) {
    for (let i = 0; i < test.images.length; i++) {
      images.push({ src: test.images[i].large });
    }
  }

  const params = {
    parallax: true,
    slidesPerView: 1,
    speed: 3000,
    loop: true,
    autoplay: {
      disableOnInteraction: true,
    },
    effect: "fade",
  };
  return !test || !test.title || !test.content ? (
    <Redirect to={"/" + route} />
  ) : (
    <Row className={manifestData?.config?.additionalTailwindStyles}>
      {subtypes.includes("imageAtTop") && test.images.length !== 0 ? (
        <Col className="OCVBlogDetailColOne" xs={10} sm={8} md={4}>
          <Swiper {...params}>
            {test.images.map(
              (image: { large: string; small: string }, index: number) => (
                <img
                  alt={image.large}
                  onClick={(e) => openLightbox(e, index)}
                  key={index}
                  className="object-contain"
                  src={test.images.length !== 0 ? image.large : " "}
                />
              )
            )}
          </Swiper>
          <Lightbox
            onClickNext={gotoNext}
            onClickPrev={gotoPrevious}
            backdropClosesModal
            currentImage={currentImage}
            onClose={closeLightbox}
            isOpen={lightboxIsOpen}
            images={images}
          />
        </Col>
      ) : (
        ""
      )}
      <Col className="OCVBlogDetailColTwo" xs={10}>
        {!subtypes.includes("noBackToList") && (
          <button
            className={"OCVBlogDetailButton p-2 rounded-lg my-2 text-gray-50"}
            onClick={() => history.push("/" + route)}
            style={{
              backgroundColor: props?.manifest?.stylesheet?.colors?.primary,
            }}
          >
            &larr; Back to List
          </button>
        )}
        <Paper className="OCVBlogDetailPaper">
          <h1>{test.title}</h1>
          {"date" in test && displayDate ? (
            <span className="OCVBlogDetailSpan">
              <strong>
                Posted on{" "}
                {manifestData?.config?.dateFormat
                  ? moment
                      .unix(test?.date?.sec)
                      .format(`${manifestData?.config?.dateFormat}`)
                      .toString()
                  : moment
                      .unix(test?.date?.sec)
                      .format("ddd, MMM DD, YYYY, hh:mm A z")
                      .toString()}
                <hr className="bg-yellow-400 w-2/12 py-0.5"></hr>
              </strong>
            </span>
          ) : null}
          <div
            dangerouslySetInnerHTML={{
              __html: test.content
                .replaceAll(
                  "<h6>",
                  '<h6 class="text-xs font-bold break-words">'
                )
                .replaceAll(
                  "<h5>",
                  '<h5 class="text-sm font-bold break-words">'
                )
                .replaceAll(
                  "<h4>",
                  '<h4 class="text-base font-bold break-words">'
                )
                .replaceAll(
                  "<h3>",
                  '<h3 class="text-xl font-bold break-words">'
                )
                .replaceAll(
                  "<h2>",
                  '<h2 class="text-2xl font-bold break-words">'
                )
                .replaceAll(
                  "<h1>",
                  '<h1 class="text-4xl font-bold break-words">'
                )
                .replaceAll("<ul>", '<ul class="list-disc m-2 p-2">')
                .replaceAll("<ol>", '<ol class="list-decimal m-2 p-2">')
                .replaceAll(
                  "<a",
                  '<a class="underline text-blue-800 break-words" '
                ),
            }}
          />
        </Paper>
      </Col>
      {!subtypes.includes("imageAtTop") && test.images.length !== 0 ? (
        <Col className="OCVBlogDetailColOne" xs={10} sm={8} md={4}>
          <Swiper {...params}>
            {test.images.map(
              (image: { large: string; small: string }, index: number) => (
                <img
                  alt={image.large}
                  onClick={(e) => openLightbox(e, index)}
                  key={index}
                  className="object-contain"
                  src={test.images.length !== 0 ? image.large : " "}
                />
              )
            )}
          </Swiper>
          <Lightbox
            onClickNext={gotoNext}
            onClickPrev={gotoPrevious}
            backdropClosesModal
            currentImage={currentImage}
            onClose={closeLightbox}
            isOpen={lightboxIsOpen}
            images={images}
          />
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
});
