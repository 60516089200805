import React from "react";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import * as moment from "moment";
import Paper from "@material-ui/core/Paper/Paper";

export default withRouter(function DetailView(props: any) {
  const details = props?.digest?.find(
    (news: any) =>
      news?._id?.$id === props?.match?.params?.blog ||
      news?.id_str === props?.match?.params?.blog ||
      news?.facebookPostID === props?.match?.params?.blog ||
      news?.eventID === props?.match?.params?.blog ||
      news?.epoch === Number(props?.match?.params?.blog)
  );
  return (
    <Row>
      <Col xs={10} className="my-4">
        <button
          style={{
            backgroundColor: props?.manifestData?.stylesheet?.colors?.primary,
          }}
          className="p-2 rounded-lg my-2 text-gray-50"
          onClick={() => props?.history?.push(`/${props?.route}`)}
        >
          &larr; Back to List
        </button>
        <Paper className="p-4">
          <h2 className="text-xl font-semibold">{details?.title}</h2>
          <strong>
            Posted on{" "}
            {props?.viewData?.config?.dateFormat
              ? moment
                  .unix(details?.epoch)
                  .format(`${props?.viewData?.config?.dateFormat}`)
                  .toString()
              : moment
                  .unix(details?.epoch)
                  .format("ddd, MMM DD, YYYY, hh:mm A z")
                  .toString()}
            <hr className="bg-yellow-400 w-2/12 py-0.5"></hr>
          </strong>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{
              __html: details?.content
                .replaceAll(
                  "<h6>",
                  '<h6 class="text-xs font-bold break-words">'
                )
                .replaceAll(
                  "<h5>",
                  '<h5 class="text-sm font-bold break-words">'
                )
                .replaceAll(
                  "<h4>",
                  '<h4 class="text-base font-bold break-words">'
                )
                .replaceAll(
                  "<h3>",
                  '<h3 class="text-xl font-bold break-words">'
                )
                .replaceAll(
                  "<h2>",
                  '<h2 class="text-2xl font-bold break-words">'
                )
                .replaceAll(
                  "<h1>",
                  '<h1 class="text-4xl font-bold break-words">'
                )
                .replaceAll("<ul>", '<ul class="list-disc m-2 p-2">')
                .replaceAll("<ol>", '<ol class="list-decimal m-2 p-2">')
                .replaceAll(
                  "<a",
                  '<a class="underline text-blue-800 break-words" '
                ),
            }}
          />
        </Paper>
        <div className="flex justify-center items-center my-2">
          {details?.images?.length > 0 ? (
            details?.images[0]?.large ? (
              <img src={details?.images[0]?.large} alt={details?.summary} />
            ) : (
              <img src={details?.images[0]?.small} alt={details?.summary} />
            )
          ) : null}
        </div>
      </Col>
    </Row>
  );
});
