import React, { Component } from "react";
import opelika from "../styles/jss/OpelikaUtilities";
import webOne from "../styles/jss/WebOne";
import OCVManifest from "../manifest/OCVManifest";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { withRouter } from "react-router";
import "whatwg-fetch";
import "./Layouts.css";

export default withRouter(
  class Home extends Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {
        manifest: "",
        version: process.env.REACT_APP_VERSION,
        build: process.env.REACT_APP_BUILD,
        routes: null,
      };
    }

    async componentDidMount() {
      try {
        const response = await fetch(
          process.env.REACT_APP_MANIFEST_URL
            ? process.env.REACT_APP_MANIFEST_URL
            : "https://cdn.myocv.com/ocvapps/a12722222/public/int_webManifest.json",
          { mode: "cors" }
        );
        const data = await response.json();
        await this.setState({
          manifestData:
            data["data"]["manifest"][
              data["data"]["lookup"]["web"][this.state.version][
                this.state.build
              ]
            ],
          routes: OCVManifest.buildRoutes(
            data["data"]["manifest"][
              data["data"]["lookup"]["web"][this.state.version][
                this.state.build
              ]
            ],
            opelika
          ),
        });
      } catch (e) {
        console.log(e);
      }
    }

    getStyles = (styles: string, colors: any, theme: string) => {
      switch (styles) {
        case "webOne":
          return webOne(colors, theme);
        case "webTwo":
          return opelika(colors);
        default:
          return webOne(colors, theme);
      }
    };

    render() {

      if (this.state.manifestData == null) {
        return (
          <img
            alt="Loading"
            src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
            className="loading-centered"
          />
        );
      } else {
        return (
          <Container
            style={{
              padding: "0",
              overflowX: "hidden",
              backgroundColor: "#eee",
            }}
            fluid
          >
            <Helmet>
              <link
                rel="icon"
                type="image/png"
                href={
                  this.state.manifestData["stylesheet"] != null
                    ? this.state.manifestData["stylesheet"].images[
                        this.state.manifestData.favicon
                      ]["url"]
                    : ""
                }
                sizes="48x48"
              />
            </Helmet>
            {OCVManifest.buildRoutes(
              this.state.manifestData,
              this.getStyles(
                this.state.manifestData["webDesign"],
                this.state.manifestData["stylesheet"]["colors"],
                this.state.manifestData["theme"]
              )
            )}
          </Container>
        );
      }

    }
  }
);
