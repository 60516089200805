import React from "react";
import { Row, Col } from "reactstrap";
import Paper from "@material-ui/core/Paper/Paper";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function OCVContactsEntry({ entry }: { [key: string]: any }) {
  return (
    <Paper className="mb-3">
      <Row className="justify-evenly py-6">
        <Col
          className={"flex items-center justify-center"}
          xs={12}
          sm={12}
          md={2}
          style={{ width: "250px", height: "auto" }}
        >
          {"image" in entry ? (
            <div>
              <img
                alt={entry.image}
                className={"img-fluid w-full h-auto "}
                src={entry.image === null ? "" : entry.image}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col className={""} xs={12} sm={12} md={8}>
          <h3 className="p-1 text-2xl font-semibold">{entry.title}</h3>
          {"jobtitle" in entry ? (
            <p className="p-1 text-xl">{entry.jobtitle}</p>
          ) : (
            ""
          )}
          <p className={"p-1 text-lg"}>
            {"address" in entry ? (
              <span>
                <a
                  className="pb-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"http://maps.google.com/?q=" + entry.address}
                >
                  {entry.address}
                </a>
                <br />
              </span>
            ) : (
              ""
            )}
            {"email" in entry ? (
              <span className="text-lg">
                {" "}
                Email: <a href={"mailto:" + entry.email}>{entry.email}</a>
                <br />
              </span>
            ) : (
              ""
            )}
            {"phone" in entry ? (
              <span className="text-lg">
                {" "}
                Phone: <a href={"tel:" + entry.phone}>{entry.phone}</a>
                <br />
              </span>
            ) : (
              ""
            )}
            {"fax" in entry ? (
              <span className="text-lg">
                Fax: <a href={"tel:" + entry.fax}>{entry.fax}</a>
                <br />
              </span>
            ) : (
              ""
            )}
          </p>
          <p>
            {"website" in entry ? (
              <span className="text-lg p-1">
                <a
                  href={
                    !entry.website.includes("http")
                      ? "https://" + entry.website
                      : entry.website
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Visit Website
                </a>
              </span>
            ) : (
              ""
            )}
          </p>
          {"description" in entry ? (
            <p className={"p-1 text-lg"}>{entry.description}</p>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Paper>
  );
}
