import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import hash from "object-hash";

const axios = require("axios");

// React-Google-Maps Documentation
// https://tomchentw.github.io/react-google-maps/#documentation

const MapView = withScriptjs(
  withGoogleMap(
    ({
      isMarkerShown,
      locations,
      filterLocation,
      handleClick,
      center,
      zoom,
      searchName,
      searchAddress,
      clusterSize,
    }) => {
      return locations ? (
        <GoogleMap
          // this is specifically for when a marker/person is clicked
          // it changes the center to that marker
          defaultCenter={{
            lat: locations?.data?.county?.latitude,
            lng: locations?.data?.county?.longitude,
          }}
          defaultOptions={{
            scrollwheel: true,
            gestureHandling: "auto",
          }}
          defaultZoom={9}
          center={
            center
              ? { lat: center?.lat, lng: center?.lng }
              : {
                  lat: locations?.data?.county?.latitude,
                  lng: locations?.data?.county?.longitude,
                }
          }
          zoom={zoom ? zoom : 9}
        >
          <MarkerClusterer
            zoomOnClick={true}
            minimumClusterSize={clusterSize ? clusterSize : 5}
          >
            {filterLocation(locations, searchName, searchAddress)?.map(
              (location, index) =>
                isMarkerShown && (
                  <Marker
                    onClick={() => handleClick(location)}
                    key={hash(location?.title + index)}
                    position={{
                      lat: location?.latitude,
                      lng: location?.longitude,
                    }}
                  />
                )
            )}
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        ""
      );
    }
  )
);

const SearchBar = ({ value, placeholder, onChange }) => {
  return (
    <input
      className="w-5/6 mx-auto p-2 rounded-lg bg-gray-50 focus:bg-white focus:ring-blue-400 focus:outline-none"
      type="search"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const MapMembers = ({
  members,
  handleClick,
  searchName,
  setSearchName,
  searchAddress,
  setSearchAddress,
  filterLocation,
  isActive,
  setIsActive,
}) => {
  return members ? (
    <div className="flex flex-col w-full h-96 p-2">
      <div className="flex flex-row p-2 max-h-16 rounded-lg">
        <SearchBar
          value={isActive.active === "name" ? searchName : searchAddress}
          placeholder={`Search by ${isActive.active}...`}
          onChange={
            isActive.active === "name" ? setSearchName : setSearchAddress
          }
        />
        <div className="pl-2">
          <div
            className="w-auto flex flex-row border-2 rounded-lg transition"
            style={{ borderColor: "#085b9e" }}
          >
            <button
              className={`p-2`}
              style={
                isActive.active === "name"
                  ? { backgroundColor: "#085b9e", color: "white" }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: "name",
                })
              }
            >
              Name
            </button>
            <button
              className={`p-2 `}
              style={
                isActive.active === "address"
                  ? { backgroundColor: "#085b9e", color: "white" }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: "address",
                })
              }
            >
              Address
            </button>
          </div>
        </div>
      </div>
      <div className="h-96 overflow-y-scroll w-full p-2">
        {filterLocation(members, searchName, searchAddress).map(
          (member, index) => (
            <button
              onClick={() => handleClick(member)}
              key={hash(index + member?.title)}
              className="border-2 p-2 flex flex-row w-full"
            >
              <div className="w-24 p-2">
                <img src={member?.images[0]} alt={`${member?.title}`} />
              </div>
              <div className="p-2 w-full">
                <p>{member?.title}</p>
                <p>{member?.subtitle}</p>
                <p>{member?.address}</p>
                <p>
                  {isNaN(member?.distance)
                    ? ""
                    : member?.distance + " miles away"}
                </p>
              </div>
            </button>
          )
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

const DetailView = ({ member, handleBack }) => {
  return (
    <div className="flex flex-col md:h-96 overflow-y-scroll w-full p-2">
      <button
        className="w-36 p-2 border-2 rounded-full text-white"
        onClick={handleBack}
        style={{ backgroundColor: "#085b9e" }}
      >
        Back to List
      </button>
      <div className="flex flex-row">
        <div className="w-48 p-2">
          <img src={member?.images[0]} alt={`${member?.title}`} />
        </div>
        <div className="p-2">
          <p>{member?.title}</p>
          <p>{member?.subtitle}</p>
          <p>{member?.address}</p>
          <p>
            {isNaN(member?.distance) ? "" : member?.distance + " miles away"}
          </p>
          <div>
            <p dangerouslySetInnerHTML={{ __html: member?.content }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const OCVSexOffendersMap = ({ link, anchorID, config }) => {
  const [locations, setLocations] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [isActive, setIsActive] = useState({
    active: "name",
  });

  // gets the data of the marker or offender that is clicked
  // uses that data to update the map and isolate that offender's marker
  const handleClick = (member) => {
    setCurrentMarker(member);
    setCenter({ lat: member?.latitude, lng: member?.longitude });
    setZoom(16);
  };

  // resets values altered by the handleClick
  const handleBack = () => {
    setCurrentMarker(null);
    setCenter(null);
    setZoom(config?.zoom);
  };

  // filters the list or map of offenders depending on input
  // sorts alphabetically for name inputs
  // sorts by distance for address inputs
  function filterLocation(locations, searchName = "", searchAddress = "") {
    let filteredLocations = locations?.data?.flat;

    if (searchName.length > 1) {
      filteredLocations = filteredLocations.filter((location) =>
        location.title.toLowerCase().includes(searchName.toLowerCase())
      );

      filteredLocations.sort((a, b) => a.title - b.title);
    }
    if (searchAddress.length > 1) {
      filteredLocations = filteredLocations.filter((location) =>
        location.address.toLowerCase().includes(searchAddress.toLowerCase())
      );

      filteredLocations.sort((a, b) => a.distance - b.distance);
    }

    for (let i = 0; i < filteredLocations.length; i++) {
      filteredLocations[i]["distance"] = haversine_distance(
        userLocation,
        filteredLocations[i]
      );
    }

    return filteredLocations;
  }

  // uses geolocation to get the user's current address(if they allow it)
  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        setUserLocation(position.coords)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  // calculates the straight line distance between two sets of coordinates
  // parameters should be objects that contain latitude and longitude properties
  function haversine_distance(mk1, mk2) {
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return d.toFixed(2);
  }

  // gets the locations from the json through an api call
  useEffect(() => {
    async function fetchLocations() {
      try {
        const response = await axios.get(link);
        setLocations(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchLocations();
  }, [link]);

  // some functions that only need to be called on the component initial render
  useEffect(() => {
    getUserLocation();
    setZoom(config?.zoom);
  }, [config.zoom]);

  return (
    <div className="flex flex-col md:flex-row p-2" id={anchorID}>
      {currentMarker ? (
        <>
          <MapView
            center={center}
            zoom={zoom}
            clusterSize={config?.clusterSize}
            handleClick={handleClick}
            filterLocation={filterLocation}
            locations={locations}
            searchName={searchName}
            searchAddress={searchAddress}
            className="flex-1 w-full p-3"
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAEOZZNm6jFVe3j3ISl_Ha9BeODMYrdqaY`}
            loadingElement={<div className="h-full" />}
            containerElement={<div className="h-96 w-full" />}
            mapElement={<div className="w-full h-full" />}
          />
          <DetailView
            className="flex-1 h-96 w-full p-3"
            member={currentMarker}
            handleBack={handleBack}
          />
        </>
      ) : (
        <>
          <MapView
            center={center}
            zoom={zoom}
            clusterSize={config?.clusterSize}
            handleClick={handleClick}
            filterLocation={filterLocation}
            locations={locations}
            searchName={searchName}
            searchAddress={searchAddress}
            className="flex-1 w-full p-3"
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAEOZZNm6jFVe3j3ISl_Ha9BeODMYrdqaY`}
            loadingElement={<div className="h-full" />}
            containerElement={<div className="h-96 w-full" />}
            mapElement={<div className="w-full h-full" />}
          />
          <MapMembers
            className="flex-1 h-96 w-full p-3"
            members={locations}
            handleClick={handleClick}
            searchName={searchName}
            setSearchName={setSearchName}
            searchAddress={searchAddress}
            setSearchAddress={setSearchAddress}
            filterLocation={filterLocation}
            setIsActive={setIsActive}
            isActive={isActive}
          />
        </>
      )}
    </div>
  );
};
