import React, { useState, useEffect } from "react";
import { RenderContacts } from "./RenderContacts";
import { RenderTabs } from "./RenderTabs";
import Pagination from "../../MainComponents/Pagination";
import { Row, Col } from "reactstrap";
import ReactLoading from "react-loading";
import { useRouteMatch, useHistory } from "react-router-dom";

export default function OCVContacts({ link, route }: any) {
  const [tabs, setTabs] = useState<[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("");
  const [contacts, setContacts] = useState<{ [key: string]: Array<object> }>(
    {}
  );
  const [contactsPerPage, setContactsPerPage] = useState<Array<object>>([]);
  const [search, setSearch] = useState<string>("");

  // https://reactrouter.com/web/api/Hooks
  const history: { [key: string]: any } = useHistory();
  const match: any = useRouteMatch(`/${route}/:tab`);

  useEffect(() => {
    try {
      fetch(link, { mode: "cors" })
        .then((response) => response.json())
        .then((data) => {
          let headers = data?.headers;
          let entries = data?.entries;
          // if a "default" header is passed but it doesn't have any contacts, remove it
          if (
            headers.includes("default") &&
            (entries["default"] === undefined ||
              entries["default"].length === 0)
          ) {
            setTabs(headers.filter((header: string) => header !== "default"));
          } else {
            setTabs(headers);
          }
          setContacts(entries);
          if (match?.params?.tab) {
            setCurrentTab(
              headers.find(
                (item: string) =>
                  item.toLowerCase().replace(/\s/g, "") ===
                  match.params.tab.toLowerCase()
              )
            );
          } else {
            setCurrentTab(headers[0]);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, [link, match?.params?.tab]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTabChange = (e: any, value: string) => {
    setCurrentTab(value);
  };

  const handleClick = (e: any, value: string) => {
    history.replace(`/${route}/${value}`);
  };

  const onPageChanged = (data: { currentPage: number; pageLimit: number }) => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    setContactsPerPage(contacts[currentTab]?.slice(offset, offset + pageLimit));
  };

  return (
    <>
      {Object.keys(contacts).length === 0 ? (
        <div className="h-24 md:h-56 lg:h-72">
          <ReactLoading
            className={"loading-centered flex items-center"}
            type={"bars"}
            color={"#000"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      ) : (
        <Row className={"flex-col items-center justify-center mt-4"}>
          <div className="p-2 sm:w-8/12 w-10/12 h-10">
            <input
              type="search"
              className="w-full rounded-full p-2"
              value={search}
              onChange={handleSearch}
              placeholder="Search contact.."
            />
          </div>
          <RenderTabs
            currentTab={currentTab}
            onChange={handleTabChange}
            tabs={tabs}
            onClick={handleClick}
          />
          <RenderContacts
            search={search}
            contacts={contacts}
            currentTab={currentTab}
            contactsPerPage={contactsPerPage}
          />
          <Row>
            <Col xs={12} md={8}>
              <Pagination
                totalRecords={contacts[currentTab]?.length}
                pageLimit={15}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
}
