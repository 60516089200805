import Feature from "./Feature/Feature";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import "./FeatureBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

export default function FeatureBar(props: any) {
  const { featureBarConfig, features, manifestData, classes } = props;
  library.add(fab, far, fal, fad, fas);
  let featureItems = featureBarConfig.items.map((item: any) =>
    features && features[item.feature] ? (
      <Feature
        manifestData={manifestData}
        bg={featureBarConfig.bg}
        url={
          features[item.feature]["url"] !== null
            ? features[item.feature]["url"]
            : ""
        }
        subtype={
          features[item.feature]["subtype"] !== null
            ? features[item.feature].subtype
            : []
        }
        image={item.image !== null ? item.image : ""}
        type={features[item.feature].type}
        feature={item.feature}
        key={item.title}
        classes={classes}
        width={item.width}
        height={item.height}
        color={featureBarConfig.itemColor}
        iconType={item.icon}
        title={item.title}
      />
    ) : (
      ""
    )
  );
  return (
    <div className={featureBarConfig?.additionalTailwindStyles}>
      {props.featureBar?.featureBarTitle && (
        <div
          className="text-center font-bold text-white text-2xl mt-2"
          style={{ backgroundColor: manifestData.stylesheet.colors.primary }}
        >
          {props.featureBar.featureBarTitle}
        </div>
      )}
      <Row id={props.anchorID} className={classes.featureBar}>
        <div className="featureBarDiv w-full flex flex-col lg:flex-row justify-center items-center">
          <Col
            className={
              " flex flex-col md:gap-4 md:flex-row items-center justify-center md:justify-evenly h-36 my-3"
            }
            xs={12}
            lg={7}
          >
            {featureItems}
          </Col>
        </div>
      </Row>
    </div>
  );
}
