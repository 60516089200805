import Paper from "@material-ui/core/Paper/Paper";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import { Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// this function is purely visual and was made to lessen the clutter of the return statement
function scrollButtons(props: any) {
  if (props.direction === "left" && props.visible)
    return (
      <button aria-label="Left arrow" style={{ width: "10rem" }} {...props}>
        <FontAwesomeIcon
          size="lg"
          icon={["fas", "chevron-left"]}
          color="black"
          className="w-full"
        />
      </button>
    );
  else if (props.direction === "right" && props.visible)
    return (
      <button aria-label="Right arrow" style={{ width: "10rem" }} {...props}>
        <FontAwesomeIcon
          size="lg"
          icon={["fas", "chevron-right"]}
          color="black"
          className="w-full"
        />
      </button>
    );
  else return null;
}

export const RenderTabs = ({ currentTab, onChange, tabs, onClick }: any) => (
  <Col xs={12} lg={10} className="mt-12 w-full">
    <Paper>
      <Tabs
        className="mb-2"
        value={currentTab}
        onChange={onChange}
        variant="scrollable"
        indicatorColor={"primary"}
        scrollButtons="auto"
        ScrollButtonComponent={(props: object): any => scrollButtons(props)}
      >
        {tabs.map((item: string) => (
          <Tab
            key={item}
            value={item}
            id={item}
            label={item}
            onClick={(e) => onClick(e, item.toLowerCase().replace(/\s/g, ""))}
          />
        ))}
      </Tabs>
    </Paper>
  </Col>
);
