import React from "react";
import { withFormsy } from "formsy-react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField/TextField";
import "./OCVFormComponents.css";

export default withFormsy(function OCVDropdown(props: any) {
  const changeValue = (event: { target: { value: any } }) => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    props.setValue(event.target.value);
  };

  const { label, getValue, multiple, elements, getErrorMessage, required } =
    props;
  let tempValue = multiple === true ? [] : "";
  return (
    <div className="OCVFormComponentsDiv">
      <TextField
        value={getValue() || tempValue}
        onChange={changeValue}
        fullWidth
        InputLabelProps={{ style: { fontSize: 12, width: "90%" } }}
        label={label}
        select
        variant="outlined"
        SelectProps={{
          multiple: multiple || false,
        }}
        required={required}
      >
        {elements.map((item: any, index: string | number) => (
          <MenuItem key={item + "-" + index} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
      <span className="OCVFormComponentsSpan">{getErrorMessage()}</span>
    </div>
  );
});
